/* Import Bootstrap */
@import url('https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css');

/* Client-specific styles */
.Client-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.Client-chat-toggle-button {
  background-color: #007bff;
  border: none;
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.Client-chat-icon {
  width: 70%;
  height: 70%;
}

.Client-chat-window {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 800px;
  margin: auto;
  position: relative;
}

.Client-chat-close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.Client-messages {
  max-height: 500px;
  overflow-y: auto;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.Client-message {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 8px;
  max-width: 80%;
  display: inline-block;
  clear: both;
}

.Client-message.Client {
  background-color: #007bff;
  color: white;
  align-self: flex-end;
  text-align: right;
  border-bottom-right-radius: 0;
}

.Client-message.Admin {
  background-color: #11c01a;
  color: #ffffff;
  align-self: flex-start;
  text-align: left;
  border-bottom-left-radius: 0;
}

.Client-message.finalized {
  background-color: #f8d7da;
  color: #842029;
  text-align: center;
  align-self: stretch; /* Hacer que el elemento ocupe todo el ancho */
  width: 100%; /* Asegurarse de que el ancho sea el 100% del contenedor */
  text-align: center; /* Centrar el texto */
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; /* Quitar el radio del borde inferior derecho para que coincida con el izquierdo */
  padding: 10px; /* Añadir algo de relleno para mejorar la legibilidad */
  box-sizing: border-box; /* Incluir el relleno y el borde en el ancho y alto total del elemento */
}

.Client-message-content {
  display: flex;
  justify-content: space-between;
}

.Client-message-timestamp {
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.5);
  align-self: flex-end;
  margin-left: 10px;
  margin-right: 10px;
}

.Client-message-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Client-message-input input {
  flex-grow: 1;
  padding: 10px;
  border-radius: 4px;
  margin-right: 10px;
}

.Client-message-input button {
  padding: 10px 20px;
  border-radius: 8px;
  margin-left: 10px;
}

.Client-question-buttons {
  display: flex;
  flex-direction: column;
}

.Client-question-buttons .btn {
  margin-bottom: 10px;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1040;
}

.modal-backdrop.show {
  opacity: 0.5;
}

/* Media queries for responsiveness */
@media (min-width: 768px) {
  .Client-chat-window {
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .Client-chat-window {
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  .Client-chat-window {
    max-width: 100%;
  }
}
.Client-message-image {
  max-width: 100%; /* Ajusta el tamaño máximo de la imagen según tus necesidades */
  height: auto;
}
